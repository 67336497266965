<template>
  <v-col cols="12" sm="6" md="4" lg="3">
    <v-card class="mx-auto background-blue" max-width="344">
      <v-img :src="imageSrc" height="200px"></v-img>

      <v-card-title>
        {{ name }}
      </v-card-title>

      <v-card-subtitle>
        {{ subName }}
      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" class="background-orange" text @click="showDialog =true">
          Bekijken
        </v-btn>
      </v-card-actions>

    </v-card>

    <div class="text-center">
      <v-dialog v-model="showDialog" width="500" style="z-index: 1001;">
        <v-card style="background-color: #DAEFFF; color: black;">

          <v-card-title class="text-h5">
            {{ name }}
          </v-card-title>

          <v-card-text>
            <div class="d-flex justify-center pb-5">
              <v-img :src="imageSrc" width="100%" :max-width="imageMaxWith"></v-img>
            </div>
            <div v-html="text" style="color: black;"></div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showDialog = false">
              Sluiten
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>

export default {
  name: "dashboardWiseInformationCard",
  components: {},
  props: {
    name: {
      type: String,
      required: true
    },
    subName: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    imageMaxWith: {
      type: Number,
      default: 300,
    },
    text: {
      type: String,
      required: true
    },
  },
  data: () => ({
    showDialog: false,
  }),
};
</script>
